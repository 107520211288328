/* eslint-disable @typescript-eslint/no-explicit-any */
import { InputProps, FormItemProps } from "antd"
import { InputStyle, StyleInput,PasswordInputStyle,TextAreaStyle } from "./InputField.style"

interface Inputprops extends InputProps {
  label?: string | any
  textareaHeight?: string
  touched?: any
  errors?: any
  isShowLabel?: boolean
  isMultiple?: boolean
  onChange?: (
    e: React.ChangeEvent<HTMLInputElement & HTMLTextAreaElement>
  ) => void
}
type Props = Inputprops & FormItemProps
function InputField ( props: Props ) {
  const {
    addonBefore,
    placeholder,
    type,
    size,
    max,
    min,
    prefix,
    suffix,
    status,
    label,
    name,
    disabled,
    onChange,
    value,
    maxLength,
    defaultValue,
    autoFocus,
    textareaHeight,
    onPressEnter,
    onBlur,
    onFocus,
    touched,
    errors,
    isShowLabel = false,
    // isMultiple = false
  } = props

  let input = (
    <InputStyle
      name={name}
      addonBefore={addonBefore}
      placeholder={placeholder}
      type={type}
      size={size}
      min={min}
      max={max}
      prefix={prefix}
      suffix={suffix}
      status={status}
      disabled={disabled}
      onChange={onChange}
      value={value}
      maxLength={maxLength}
      defaultValue={defaultValue}
      autoFocus={autoFocus}
      onPressEnter={onPressEnter}
      onBlur={onBlur}
      onFocus={onFocus}
    />
  )
  if ( type === "password" ) {
    // input = (
    //   <InputStyle.Password
    //     placeholder={placeholder}
    //     onChange={onChange}
    //     status={status}
    //     maxLength={maxLength}
    //   />
    // )

    input = (
      <PasswordInputStyle
        placeholder={placeholder}
        onChange={onChange}
        status={status}
        maxLength={maxLength}
      />
    );
  }
  if ( type === "textarea" ) {
    // input = (
    //   <InputStyle.TextArea
    //     style={{ height: textareaHeight || 120 }}
    //     placeholder={placeholder}
    //     onChange={onChange}
    //     value={value}
    //     name={name}
    //     maxLength={maxLength}
    //   />
    // )

    input = (
      <TextAreaStyle
        style={{ height: textareaHeight || 120 }}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        name={name}
        maxLength={maxLength}
      />
    );
  }
  return (
    <StyleInput>
      {isShowLabel && (
        <div className="input-label">
          <p>{label || name}</p>
        </div>
      )}
      {input}
      {touched && errors && touched[ name ] && errors[ name ] && (
        <span className="text-error">{errors[ name ]}</span>
      )}
    </StyleInput>
  )
}
export default InputField
