import { Form, Input, InputNumber } from "antd"
import styled from "styled-components"

export const InputStyle = styled( Input )`
  background-color: transparent;
  &:disabled {
    color: ${( props ) => props.theme.textcolorlightgrey};
  }
  &::placeholder {
    color: ${( props ) => props.theme.textcolorlightgrey};
    opacity: 1;
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${( props ) => props.theme.textcolorlightgrey};
    opacity: 1;
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: ${( props ) => props.theme.textcolorlightgrey};
    opacity: 1;
  }

  .ant-input {
    font-size: 14px;
    background-color: transparent;
  }
  &:hover {
    border-color: ${( props ) => props.theme.colorlightblack};
  }
  &:focus {
    border-color: ${( props ) => props.theme.colorlightblack};
    box-shadow: none;
  }
`

export const PasswordInputStyle = styled(Input.Password)`
  background-color: transparent;
  &:disabled {
    color: ${(props) => props.theme.textcolorlightgrey};
  }
  &::placeholder {
    color: ${(props) => props.theme.textcolorlightgrey};
    opacity: 1;
  }
  &:-ms-input-placeholder {
    color: ${(props) => props.theme.textcolorlightgrey};
    opacity: 1;
  }
  &::-ms-input-placeholder {
    color: ${(props) => props.theme.textcolorlightgrey};
    opacity: 1;
  }
  .ant-input {
    font-size: 14px;
    background-color: transparent;
  }
  &:hover {
    border-color: ${(props) => props.theme.colorlightblack};
  }
  &:focus {
    border-color: ${(props) => props.theme.colorlightblack};
    box-shadow: none;
  }
`;

export const TextAreaStyle = styled(Input.TextArea)`
  background-color: transparent;
  &:disabled {
    color: ${(props) => props.theme.textcolorlightgrey};
  }
  &::placeholder {
    color: ${(props) => props.theme.textcolorlightgrey};
    opacity: 1;
  }
  &:-ms-input-placeholder {
    color: ${(props) => props.theme.textcolorlightgrey};
    opacity: 1;
  }
  &::-ms-input-placeholder {
    color: ${(props) => props.theme.textcolorlightgrey};
    opacity: 1;
  }
  .ant-input {
    font-size: 14px;
    background-color: transparent;
  }
  &:hover {
    border-color: ${(props) => props.theme.colorlightblack};
  }
  &:focus {
    border-color: ${(props) => props.theme.colorlightblack};
    box-shadow: none;
  }
`;

export const InputNumberStyle = styled( InputNumber )`
  background-color: transparent;
  &:disabled {
    color: ${( props ) => props.theme.textcolorlightgrey};
  }
  &::placeholder {
    color: ${( props ) => props.theme.textcolorlightgrey};
    opacity: 1;
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${( props ) => props.theme.textcolorlightgrey};
    opacity: 1;
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: ${( props ) => props.theme.textcolorlightgrey};
    opacity: 1;
  }

  .ant-input {
    font-size: 14px;
    background-color: transparent;
  }
  &:hover {
    border-color: ${( props ) => props.theme.colorlightblack};
  }
  &:focus {
    border-color: ${( props ) => props.theme.colorlightblack};
    box-shadow: none;
  }
`

export const FormStyle = styled( Form.Item )`
  &.ant-form-item {
    width: 100%;
  }
  .ant-row {
    display: block;
  }
  .ant-form-item-explain {
    font-size: 12px;
    color: ${( props ) => props.theme.colordarkgrey};
  }
  &.ant-form-item {
    .ant-form-item-label {
      label {
        font-size: 14px;
        height: auto;
        color: ${( props ) => props.theme.textcolordblack};
        &::after {
          display: none;
        }

        &.ant-form-item-required {
          &::after {
            content: "*";
            display: inline-block;
            margin-inline-end: 4px;
            color: ${( props ) => props.theme.colordanger};
            font-size: 14px;
            line-height: 1;
            font-family: SimSun, sans-serif;
          }

          &::before {
            content: none;
          }
        }
      }
    }
  }
  .ant-input-affix-wrapper {
    font-size: 14px;
    background: transparent;
    .ant-input {
      background: transparent;
      color: ${( props ) => props.theme.textcolordblack};
      &::placeholder {
        color: ${( props ) => props.theme.textcolorlightgrey};
        opacity: 1;
      }

      &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: ${( props ) => props.theme.textcolorlightgrey};
        opacity: 1;
      }

      &::-ms-input-placeholder {
        /* Microsoft Edge */
        color: ${( props ) => props.theme.textcolorlightgrey};
        opacity: 1;
      }
    }
    &:focus {
      box-shadow: none;
    }
  }
  .ant-input-affix-wrapper-focused {
    box-shadow: none;
  }
`
export const StyleInput = styled.div`
  .text-error {
    font-weight: 400;
    font-size: 12px;
    color: ${( props ) => props.theme.colordanger};
  }
  .input-label {
    font-size: 12px;
    line-height: 16px;
    font-weight: 600;
    padding-bottom: 0.5rem;
  }
`
